export const gateway = "https://qat-oraclerms-bms-api.link-nonprod.wawanesalife.com";
export const assets = "https://undefined";
export const LoginUrlParameter = "https://qat-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=250hoqnbf99j00fgajtsuotvhn&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fqat-oraclerms-bms.link-nonprod.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "250hoqnbf99j00fgajtsuotvhn";
export const BrokerPoolDomainParameter = "qat-brokers";
export const BrokerPoolParameter = "ca-central-1_26CbSAlrU";

export const brokerBranding = {
	"qat-oraclerms.link-nonprod.wawanesalife.com": {
		a: {
			broker: "oraclerms",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.oraclerms.com/privacy/",
			scope: "oraclerms#branding#qat-oraclerms.link-nonprod.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "life@oraclerms.com",
			video_link: "null",
			map2: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			environment_id: "qat",
			map1: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			brands: "qat-oraclerms.link-nonprod.wawanesalife.com",
			map4: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			map3: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			phone: "1-855-884-6150",
			menuPhone: "button",
			environment: "qat",
			website: "https://www.oraclerms.com/",
			tcLink: "null",
			ftcLink: "null",
			layout: "iframe",
			g4: "G-K79QEMGKPC",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			tagline: "Reduce Your Risk",
			approve_user: "link_oraclerms",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "qat-oraclerms.link-nonprod.wawanesalife.com",
		},
		b: {
			broker: "oraclerms",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.oraclerms.com/privacy/",
			scope: "oraclerms#branding#qat-oraclerms.link-nonprod.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "life@oraclerms.com",
			video_link: "null",
			map2: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			environment_id: "qat",
			map1: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			brands: "qat-oraclerms.link-nonprod.wawanesalife.com",
			map4: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			map3: "100 Drumlin Circle,Concord,ON,L4K 3E5",
			phone: "1-855-884-6150",
			menuPhone: "button",
			environment: "qat",
			website: "https://www.oraclerms.com/",
			tcLink: "null",
			ftcLink: "null",
			layout: "iframe",
			g4: "G-K79QEMGKPC",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			tagline: "Reduce Your Risk",
			approve_user: "link_oraclerms",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "qat-oraclerms.link-nonprod.wawanesalife.com",
		},
	},
};

//updated on Wed Jul 17 2024 04:10:28 GMT+0000 (Coordinated Universal Time)
